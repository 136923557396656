import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  constructor(public authService: AuthService, public menuCtrl: MenuController, private router: Router) { }

  onClickedButtonMenu(navpage){
    this.router.navigate([navpage]);
    this.menuCtrl.close();
  }

  ngOnInit() {}

}
